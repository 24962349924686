import React from "react";
import ImageSlideCarousels from "../components/imageslidecarousels";
import Card from "../components/card";


 function Home() {
    return(
        <>
         <ImageSlideCarousels  />
         <Card />
        </>
    )
}
export default Home