export const productitemslist = {
    "Gynecology & Obstetrics": [
        {
            "id": 1,
            "title": "FETL-Z",
            "description": "Carbonyl Iron 100mg + Zinc 61.8mg + Folic Acid 1.5 mg + B6 3 mg + B12 10 mcg + Calcium Depantothenate 10 mg Capsules."
        },
        {
            "id": 2,
            "title": "FETL-Z syrup.",
            "description": "Elemental Iron 50 mg + Folic Acid 0.75 mg + Cyanocobalamin 6 mcg + Elemental Zinc 11 mg Syrup."
        },
        {
            "id": 3,
            "title": "K-INNRICH",
            "description": "Calcium Citrate 1gm. + Vit. D3 400 IU + Elemental Zinc 7.5 mg + Magnesium 100 mg"

        },
        {
            "id": 4,
            "title": "CDERICH Syrup.",
            "description": "Calcium Carbonate 625 mg + Elemental Calcium 250 mg + Vit D3 125 I.U. + Zinc Sulphate 5 mg + Lysine Monohydrochloride 50 mg"

        },
        {
            "id": 5,
            "title": "FLOINN - 200/400",
            "description": "Ofloxacin 200 mg Tablets / Ofloxacin 400 mg Tablets"

        },
        {
            "id": 6,
            "title": "FLOINN - 0Z",
            "description": "Ofloxacin 200 mg + Ornidazole 500 mg Tablets || 10ml, 30ml"

        },
        {
            "id": 7,
            "title": "INNOVIT",
            "description": "Antioxidants + Multi Mineral + Multi Vitamin Tablets"

        },
        {
            "id": 8,
            "title": "INNOVIT Syrup.",
            "description": "Antioxidants + Multi Mineral + Multi Vitamin Syrup"

        },
        {
            "id": 9,
            "title": "INGEST - 100/200",
            "description": "Progesterone 100 mg Tablets / Progesterone 200 mg Tablets"

        }
    ],
    "Orthopedic": [
        {
            "id": 1,
            "title": "ALPHA INNRICH",
            "description": "AlphaCalcidol 0.25mg + Calcium Citrate 1gm + beta Carotene 10mg Tablets."
        },
        {
            "id": 2,
            "title": "K-INNRICH",
            "description": "Calcium Citrate 1gm. + Vit. D3 400 IU + Elemental Zinc 7.5 mg + Magnesium 100 mg"
        },
        {
            "id": 3,
            "title": "K-INNRICH Syrup.",
            "description": "Calcium Carbonate 625 mg + Elemental Calcium 250 mg + Vit D3 125 I.U. + Zinc Sulphate 5 mg + Lysine Monohydrochloride 50 mg"

        },
        {
            "id": 4,
            "title": "EOMOVAS - PG",
            "description": "Pregabalin 75 mg Methylcobalamine 750 mcg"

        },
        {
            "id": 5,
            "title": "INNOVIT",
            "description": "Antioxidants + Multi Mineral + Multi Vitamin Tablets"

        },
        {
            "id": 6,
            "title": "INNOVIT Syrup.",
            "description": "Antioxidants + Multi Mineral + Multi Vitamin Syrup"

        },
        {
            "id": 7,
            "title": "RABEIN",
            "description": "Rabeprazole 20 mg Tablets"

        },
        {
            "id": 8,
            "title": "INNOZOX",
            "description": "Diclofenac Potassium 50 mg + Chlorzoxazone 250 mg + Paracetamol 500 mg Tablets"

        },
        {
            "id": 9,
            "title": "INNOTRAP",
            "description": "Tramadol Hydrochloride 37.5 mg + Paracetamol 325 mg Tablets"

        },
        {
            "id": 10,
            "title": "FEV - 650",
            "description": "Paracetamol 650 mg Tablets"

        },
        {
            "id": 11,
            "title": "INNONAC ASP",
            "description": "Acelofenac 100 mg + Paracetamol 500mg + Serratiopeptidase 15 mg Tablets"

        }
    ],
    "Physican": [
        {
            "id": 1,
            "title": "CEFINN - 100/200",
            "description": "Cefixime 100 mg DT / Cefixime 200 mg DT"
        },
        {
            "id": 2,
            "title": "CEFINN DS",
            "description": "Cefixime 50 mg / 5 ml Dry Syrup"
        },
        {
            "id": 3,
            "title": "I - DOXIM - 100/200",
            "description": "Cefpodoxime Proxetil 100 mg Tablets / Cefpodoxime Proxetil 200 mg Tablets"

        },
        {
            "id": 4,
            "title": "I - DOXIM DS",
            "description": "Cefpodoxime Proxetil 50 mg / 5 ml Dry Syrup"

        },
        {
            "id": 5,
            "title": "I - DOXIM CV",
            "description": "Cefpodoxime Proxetil 200 mg, Calvulanate 1250mg"

        },
        {
            "id": 6,
            "title": "FLOINN - 200/400",
            "description": "Ofloxacin 200 mg Tablets / Ofloxacin 400 mg Tablets"

        },
        {
            "id": 7,
            "title": "FLOINN - 0Z",
            "description": "Ofloxacin 200 mg + Ornidazole 500 mg Tablets"

        },
        {
            "id": 8,
            "title": "FLOINN - C",
            "description": "Ofloxacin 200 mg + Cefixime 200 mg Tablets"

        },
        {
            "id": 9,
            "title": "INNOTRAP",
            "description": "Tramadol Hydrochloride 37.5 mg + Paracetamol 325 mg Tablets"

        },
        {
            "id": 10,
            "title": "INOCON - 150",
            "description": "Fluconazole 150 mg Tablets"

        },
        {
            "id": 11,
            "title": "INOVIT",
            "description": "Antioxidants + Multi Mineral + Multi Vitamin Tablets"

        },
        {
            "id": 12,
            "title": "INOVIT Syrup.",
            "description": "Antioxidants + Multi Mineral + Multi Vitamin Syrup"

        },
        {
            "id": 13,
            "title": "INOVIT POWDER",
            "description": "Antioxidants + Multi Mineral + Multi Vitamin Protein"

        },
        {
            "id": 14,
            "title": "AZIKEM - 250",
            "description": "Azithromycin 250 mg Tablets"
        },
        {
            "id": 16,
            "title": "AZIKEM - 500",
            "description": "Azithromycin 500 mg Tablets"

        },
        {
            "id": 17,
            "title": "RABEIN",
            "description": "Rabeprazole 20 mg Tablets"

        },
        {
            "id": 18,
            "title": "RABEIN - DSR",
            "description": "Rabeprazole 20 mg + Domperidone 30 mg Capsules"

        },
        {
            "id": 19,
            "title": "PANKEM - 40",
            "description": "Pantopraozole 40 mg Tablets"

        },
        {
            "id": 20,
            "title": "ACEKEM PLUS",
            "description": "Acelofenac 100 mg + Paracetamol 500 mg Tablets"

        },
        {
            "id": 21,
            "title": "INNONAC - SR",
            "description": "Acelofenac 200 mg SR Tablets"

        },
        {
            "id": 22,
            "title": "INNONAC ASP",
            "description": "Acelofenac 100 mg + Paracetamol 500mg + Serratiopeptidase 15 mg Tablets"

        },
        {
            "id": 23,
            "title": "INOZOX",
            "description": "Diclofenac Potassium 50 mg + Chlorzoxazone 250 mg + Paracetamol 500 mg Tablets"

        },
        {
            "id": 24,
            "title": "INOTRAX 1gm",
            "description": "Ceftriaxone 1gm Injection"

        }
    ],
    "Pediatric": [
        {
            "id": 1,
            "title": "CEFINN - 100",
            "description": "Cefixime 100 mg DT"
        },
        {
            "id": 2,
            "title": "CEFINN DS",
            "description": "Cefixime 50 mg / 5 ml Dry Syrup"

        },
        {
            "id": 3,
            "title": "I - DOXIM - 100",
            "description": "Cefpodoxime Proxetil 100 mg Tablets"

        },
        {
            "id": 4,
            "title": "I - DOXIM DS",
            "description": "Cefpodoxime Proxetil 50 mg / 5 ml Dry Syrup"

        }
    ],
    "Surgery": [
        {
            "id": 1,
            "title": "CEFINN - 100/200",
            "description": "Cefixime 100 mg DT / Cefixime 200 mg DT"
        },
        {
            "id": 2,
            "title": "I - DOXIM - 100/200",
            "description": "Cefpodoxime Proxetil 100 mg Tablets / Cefpodoxime Proxetil 200 mg Tablets."

        },
        {
            "id": 3,
            "title": "I - DOXIM CV",
            "description": "Cefpodoxime Proxetil 200 mg, Calvulanate 1250mg"

        },
        {
            "id": 4,
            "title": "INNONAC - SR",
            "description": "Acelofenac 200 mg SR Tablets"

        },
        {
            "id": 5,
            "title": "ACEKEM PLUS",
            "description": "Acelofenac 100 mg + Paracetamol 500 mg Tablets"

        },
        {
            "id": 6,
            "title": "INNONAC ASP",
            "description": "Acelofenac 100 mg + Paracetamol 500mg + Serratiopeptidase 15 mg Tablets"

        },
        {
            "id": 7,
            "title": "INOTRAX 1gm",
            "description": "Ceftriaxone 1gm Injection"

        },
        {
            "id": 8,
            "title": "INOTAZ 4.5 gm",
            "description": "Piperacillin 4gm + Tazobactum 500 mg Injection"

        },
        {
            "id": 9,
            "title": "INOTRAX - T",
            "description": "Ceftriaxone 100mg + Tazobactum 125 mg Injection"

        },
        {
            "id": 10,
            "title": "I - PENEM 1gm",
            "description": "Meropenem 1gm Injection"

        }
    ],
    "Gastroenterology": [
        {
            "id": 1,
            "title": "RABEIN",
            "description": "Rabeprazole 20 mg Tablets"

        },
        {
            "id": 2,
            "title": "RABEIN - DSR",
            "description": "Rabeprazole 20 mg + Domperidone 30 mg Capsules"

        },
        {
            "id": 3,
            "title": "PANDKEM - 40",
            "description": "Pantopraozole 40 mg Tablets"

        }
        
    ],
    "Dentistry": [
        {
            "id": 1,
            "title": "CEFINN - 100/200",
            "description": "Cefixime 100 mg DT / Cefixime 200 mg DT"

        },
        {
            "id": 2,
            "title": "I - DOXIM - 100/200",
            "description": "Cefpodoxime Proxetil 100 mg Tablets / Cefpodoxime Proxetil 200 mg Tablets"

        },
        {
            "id": 3,
            "title": "K-INNRICH",
            "description": "Calcium Citrate 1gm. + Vit. D3 400 IU + Elemental Zinc 7.5 mg + Magnesium 100 mg"

        },
        {
            "id": 4,
            "title": "INNONAC - SR",
            "description": "Acelofenac 200 mg SR Tablets"

        },
        {
            "id": 5,
            "title": "ACEKEM PLUS",
            "description": "Acelofenac 100 mg + Paracetamol 500 mg Tablets"
        },
        {
            "id": 6,
            "title": "INNONAC ASP",
            "description": "Acelofenac 100 mg + Paracetamol 500mg + Serratiopeptidase 15 mg Tablets"

        }
    ],
    "Psycho": [
        {
            "id": 1,
            "title": "IEXIMIG - 10",
            "description": "Flunarizine 10 mg Tablets"

        },
        {
            "id": 2,
            "title": "ILORAFINE - 1/2",
            "description": "Lorazepam 1 mg Tablets / Lorazepam 2 mg Tablets"

        },
        {
            "id": 3,
            "title": "IREZAPIN - 5/10",
            "description": "Olanzapine 5 mg Tablets / Olanzapine 10 mg Tablets"

        },
        {
            "id": 4,
            "title": "ICLOPSY - 0.50",
            "description": "Clonazepam 0.5 mg Tablets"

        },
        {
            "id": 5,
            "title": "ICLOPSY - 0.25",
            "description": "Clonazepam 0.25 mg Tablets"

        },
        {
            "id": 6,
            "title": "ITRIDYL - 2",
            "description": "Trihyexphenidyl Hcl 2 mg Tablets"

        },
        {
            "id": 7,
            "title": "ISERWEL - 50",
            "description": "Sertraline 50 mg Tablets"

        },
        {
            "id": 8,
            "title": "ICITRAX - S 10",
            "description": "Escitalopram 10 mg Tablets"

        }

    ],
    "Immunity Boosting Vita CZ": [
        {
            "id": 1,
            "title": "IVITAMIN-C 50 mg",
            "description": "Increase energy Resistance and Immune System,keep healthy & strong"

        },
        {
            "id": 2,
            "title": "IELEMENTAL ZINC 50 mg",
            "description": "Growth development of children,better neuro psychologicalo function in a child brain"

        },
        {
            "id": 3,
            "title": "IVITA CZ",
            "description": "Double Protection strength and double physical and mental benefits"

        }
    ],
    "Opthalmic Range": [
        {
            "id": 1,
            "title": "BACILYN - D Eye drop (10ml)",
            "description": "Each ml contains:  Chloramphenicol 1% + Dexamethasone 0.1% + Benzalkonium chloride 1mg"

        },
        {
            "id": 2,
            "title": "Polycide Eye/ear drop (10ml)",
            "description": "Each ml contains:  Chloramphenicol 2mg + Neomycin sulphate 5mg + Benzalkonium chloride 1mg"

        },
        {
            "id": 3,
            "title": "Polymicin Eye/Ear drop (10ml)",
            "description": "Each ml contains: Gentamicin 0.3%w/v + Benzalkonium Chloride 1mg "

        },
        {
            "id": 4,
            "title": "Polymicin – D Eye/ear Drops (10ml)",
            "description": "Each ml contains: Gentamicin 0.3w/v + Dexamethasone 0.1%w/v + Benzalkonium Chloride 1mg "

        }
    ],
    "Parentals Range": [
        {
            "id": 1,
            "title": "Polycomplex Inj. (10ml)",
            "description": "Each ml contains: Thiamine HCl 25mg + Riboflavin Sod. Phos. 2mg + Pyridoxine HCl 2mg + D-Panthenol 5mg + Niacinamide 50mg"

        },
        {
            "id": 2,
            "title": "Ionik -D Inj. (10ml)",
            "description": "Each ml contains: Calcium Levulinate 75mg + Cholecalciferol 10000 IU + Hydroxocobalamin 100g + L-Lysine HCl 50mg"

        },
        {
            "id": 3,
            "title": "Redifol -H Inj. (10ml)",
            "description": "Each ml contains: Folic acid 10mg + Hydroxocobalamin 300g + Niacinamide 150mg"

        },
        {
            "id": 4,
            "title": "Normove Inj (10ml)",
            "description": "Each ml contains: Metoclopramide HCl 5mg "

        },
        {
            "id": 5,
            "title": "Dol Inj.(10ml)",
            "description": "Each ml contains: Diazepam 5mg "

        },
        {
            "id": 6,
            "title": "Polymicin Inj.(2ml, 10ml, 20ml, 30ml)",
            "description": "Each ml contains: Gentamicin 40mg "

        },
        {
            "id": 7,
            "title": "Polymicin Inj. (Paediatric) (2ml)",
            "description": "Each 2ml contains: Gentamicin 20mg "

        },
        {
            "id": 8,
            "title": "Polydeca Inj. (2ml)",
            "description": "Each ml contains: Nandrolone Decanoate 25mg "

        },
        {
            "id": 9,
            "title": "Dexamethasone Sod. Phos. Inj.(2ml, 10ml, 20ml)",
            "description": "Each ml contains: Dexamethasone Phosphate 4mg "

        },
        {
            "id": 10,
            "title": "Chloroquine Phosphate Inj.(30ml)",
            "description": "Each ml contains: Chloroquine 40mg"

        },
        {
            "id": 11,
            "title": "Cyanocobalamin Inj. (10ml)",
            "description": "Each ml contains: Cyanocobalamin 500g "

        },
        {
            "id": 12,
            "title": "Dolarex Inj. (30ml)",
            "description": "Each ml contains: Diclofenac sodium 25mg "

        },
        {
            "id": 13,
            "title": "Polyvil Inj.  (10ml)",
            "description": "Each ml contains: Phenaramine maleate 22.75mg "

        },
        {
            "id": 14,
            "title": "Oxytetracycline Inj. (30ml)",
            "description": "Each ml contains: Oxytetracycline 50mg "

        },
        {
            "id": 15,
            "title": "Polymikacin Inj. (2ml, 10ml)",
            "description": "Each ml contains: Amikacin 100mg"

        },
        {
            "id": 16,
            "title": "Polymikacin Inj.(250mg) - 2ml ",
            "description": "Each ml contains: Amikacin 250mg"

        },
        {
            "id": 17,
            "title": "Polymikacin Inj.(500mg) - 2ml",
            "description": "Each ml contains: Amikacin 500mg"

        },
        {
            "id": 18,
            "title": "Spamocid Inj. (10ml, 30ml)",
            "description": "Each ml contains: Dicycloamine HCl 10mg"

        },
        {
            "id": 19,
            "title": "Polycomplex Plus Inj.(6ml)",
            "description": "Each 3ml contains: Thiamine HCl 100mg + Riboflavin Sod. Phos. 5mg + Pyridoxine HCl 100mg + D-Panthenol 50mg + Niacinamide 100mg + Vitamin B12 1000mcg "

        },
        {
            "id": 20,
            "title": "Thermol Inj.(15ml)",
            "description": "Each ml contains: Paracetamol 150mg"

        },
        {
            "id": 21,
            "title": "Emetin Inj. USP (10ml)",
            "description": "Each ml contains: Ondansetron HCl USP 2mg"

        },
    ],
    "Syrup Range": [
        {
            "id": 1,
            "title": "Polylinctus (60ml , 100ml)",
            "description": "Each 5ml Contains : Dextromethorphan Hydrobromide IP 5mg +  Guaiphenesin IP 50mg + Chlorpheniramine Maleate IP 2.5mg+ Ammonium Chloride IP 60mg"

        },
        {
            "id": 2,
            "title": "DiPhenBr.  (100ml)",
            "description": "Each 5ml Contains : Diphenhydramine HCl IP 8mg + Bromhexine HCl IP  4mg + Guaiphenesin IP  50mg+ Ammonium Chloride IP  100mg + Menthol IP 1mg"

        },
        {
            "id": 3,
            "title": "Phen - CZ. (60ml)",
            "description": "Each 5ml Contains : Paracetamol IP  250mg + Phenylephrine Hydrochloride IP  5mg +  Cetrizine Hydrochloride IP  2.5mg"
        },
        {
            "id": 4,
            "title": "Redifol - Z Syrup (170ml)",
            "description": "Each 5ml Contains : Each 5ml Contains : Iron Choline Citrate  150 mg +  Folic Acid IP  1 mg + Cyanocobalamin (Vit.B12) IP  7.5mcg + Pyridoxine Hydrochloride (Vit. B6)IP  1 mg + Zinc Sulphate IP  32.5 mg"
        },
        {
            "id": 5,
            "title": "P - cyp Syrup (200ml)",
            "description": "Each 5ml Contains : Cyproheptadine Hydrochloride + (anhydrous) IP  2 mg +Tricholine Citrate  275 mg"
        },
        {
            "id": 6,
            "title": "Polycomplex Plus Syrup ( 100ml, 700ml )",
            "description": "Each 5ml Contains : Cholecalciferol IP  100 I.U + Vitamin E (As dl  Tocopheryl Acetate) IP  2.5 I.U + Thiamine Hydrochloride IP  0.75mg + Riboflavin Sodium Phosphate IP  0.75 mg + Pyridoxine Hydrochloride IP  0.5 mg + Cyanocobalamin IP  0.5 mcg + D-Panthenol IP  1.25 mg + Niacinamide IP  7.5 mg + L –Lysine Hydrochloride USP  5 mg + Potassium Iodide IP  50 mcg + Copper Sulphare IP 66  100 mcg + Zinc Sulphate IP 22.2 mg"
        },
        {
            "id": 7,
            "title": "Asmar – T ( 60 ml ,100 ml)",
            "description": "Each 5ml Contains :  Ambroxil HCl IP  15mg + Guaiphenesin IP  50mg + Terbutaline Sulphate IP  1.25mg + Menthol IP  2.5 mg"
        }
        
    ],
    "Human Injectable For Export": [
        {
            "id": 1,
            "title": "N – Deca 200 (2ml)",
            "description": "Each 2ml contains: Nandrolone decanoate 200mg"

        },
        {
            "id": 2,
            "title": "MIC Injection (10ml, 30ml)",
            "description": "Methionine USP  25 mg + Inositol  50 mg + Choline Chloride USP 50 mg"

        },
        {
            "id": 3,
            "title": "SUPER MIC Injection (10ml. 30ml)",
            "description": "Each ml Contains : Methionine USP 25 mg + Inositol 50 mg + Choline Chloride USP 50 mg + Vitamin B6 HCl B.P 100 mg + Vitamin B12 B.P 1000 Mcg"
        }
    ],
    "Eye Drops For Export": [
        {
            "id": 1,
            "title": "NEULATEN Eye Drop (2.5ml, 5.0ml)",
            "description": "Each ml Contains : Latanoprost 0.005%w/v + Benzalkonium Chloride B.P 0.02% v/v (As Preservastive) "

        },
        {
            "id": 2,
            "title": "NEULATEN – T Eye Drop. (2.5ml, 5.0ml)",
            "description": "Each ml Contains: Latanoprost  0.005%w/v+ Timolol Maleate B.P Equ. To +Timolol 0.5% w/v +Benzalkonium Chloride B.P …… 0.02% v/v (As Preservastive) "

        },
        {
            "id": 3,
            "title": "NEUDOR Eye Drop. (2.5ml, 5.0ml)",
            "description": " Each ml Contains: Dorzolamide HCl Eq. to Dorzolamide B.P 2 %w/v + Benzalkonium Chloride B.P  0.0075% v/v (As Preservastive)"

        },
        {
            "id": 4,
            "title": "NEUDOR – T Eye Drop. (2.5ml, 5.0ml)",
            "description": "Each ml Contains: Dorzolamide HCl Eq. to Dorzolamide B.P  2 %w/v + Timolol Maleate B.P Equ. To Timolol 0.5% w/v+ Benzalkonium Chloride B.P  0.0075% v/v (As Preservastive)"

        },
        {
            "id": 5,
            "title": "NEUCIPRO Eye/EAR Drop (2.5ml, 5.0ml)",
            "description": "Each ml Contains: Ciprofloxacin HCl Eq. to Ciprofloxacin USP 0.3 %w/v + HydroxyPropyl Methyl Cellulose USP 0.25% w/v + Benzalkonium Chloride B.P  0.02% v/v (As Preservastive)"

        },
        {
            "id": 6,
            "title": "NEUGEN Eye/EAR Drop (2.5ml, 5.0ml)",
            "description": "Each ml Contains: Gentamicin Sulphate Eq. to Gentamicin B.P  0.3 %w/v + Benzalkonium Chloride B.P  0.02% v/v (As Preservastive) + HydroxyPropyl Methyl Cellulose USP 0.25% w/v"

        }
    ],
    "Products Manufactured under LIC For Export": [
        {
            "id": 1,
            "title": "Oxytetracycline LA 20% Inj. (10ml, 30ml, 50ml,)",
            "description": "Each ml contains: Oxytetracycline dihydrate 200 mg "

        },
        {
            "id": 2,
            "title": "Vit. ADE Inj. (10ml, 30ml)",
            "description": "Each ml Contains : Vitamin A Palmitate 250000 I.U + Vitamin D3 25000 I.U + Vitamin E Acetate 100 mg"

        },
        {
            "id": 3,
            "title": "Trizine Inj. ( 30ml)",
            "description": "Each ml Contains : Sulphadiazine 200mg + Trimethoprim 40 mg"

        },
        {
            "id": 4,
            "title": "Meloxicam Inj. (30ml, 50ml & 100ml)",
            "description": "Each ml Contains : Meloxicam 5 mg + Benzyl Alcohol 2 % v/v"
        },
        {
            "id": 5,
            "title": "Meloxicam Inj. (DS) (30ml, 50ml & 100ml)",
            "description": "Each ml Contains :Meloxicam 10 mg + Benzyl Alcohol 2 % v/v"

        }  

    ]
}