export const carditems = [
    {
        id: 1,
        title: "Best Quality",
        description: "We deliver top-quality products to our customers because we believe that true leadership is defined by unwavering integrity and supreme quality.",
        image: require('../images/InnokemPharma_icon1_g.png'),
        cName: "card"
    },
    {
        id: 2,
        title: "Integrity",
        description: "Act with conviction and courage, always doing what is right. Maintain business honesty, impartiality, and fairness at all times. Uphold strong ethical and moral standards in all your actions.",
        image: require('../images/InnokemPharma_icon2_g.png'),
        cName: "card"
    },
    {
        id: 3,
        title: "Consistency",
        description: "Strive to introduce new products to the market while consistently delivering value. Maintain transparency in all dealings.",
        image: require('../images/InnokemPharma_icon3_g.png'),
        cName: "card"
    },
    {
        id: 4,
        title: "Innovation",
        description: "TAdopt new ideas and technologies to address unmet needs, and proactively anticipate future trends.",
        image: require('../images/InnokemPharma_icon4_g.png'),
        cName: "card"
    },
    {
        id: 5,
        title: "Passion",
        description: "Bring energy and enthusiasm to everything we do. Go the extra mile, inspire those around you, and always strive to do your best in every situation.",
        image: require('../images/InnokemPharma_icon5_g.png'),   
        cName: "card"
    },
    {
        id: 6,
        title: "Sustainability",
        description: "At InnokemPharma, we prioritize the role of patients, doctors, and pharmacists in promoting green and sustainable pharmacy practices, advocating for responsible pharmaceutical use to support environmental sustainability.",
        image: require('../images/InnokemPharma_icon6_g.png'),
        cName: "card"
    }
]