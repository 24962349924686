import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import Logo from "../images/Logo1.png"
import "./footer.css"

function Footer() {
    return (
        <footer className="footer">
            <Container fluid>
                <Row className="footer-row align-items-center">
                    <Col xs={12} md={4} className="text-center text-md-left mb-3 mb-md-0">
                        <Image src={Logo} alt="Company Logo" className="footer-img" />
                    </Col>
                    <Col xs={12} md={4} className="text-center mb-3 mb-md-0">
                        <p>&copy; {new Date().getFullYear()} Innokem Pharma</p>
                        <h6>Suite.No: #201, Sri Sai Kuteer, HMT Nagar
                        Nacharam, Hyderabad, Telangana - state, India</h6>
                    </Col>
                    <Col xs={12} md={4} className="text-center text-md-right">
                        <h6>Email: info@innokempharma.com</h6>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer
